.btn-add{
    margin: 20px 5px;
}

.add {
    margin-top: 6px;
}

.image-buttons-container {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.variant-options .form-check {
  display: flex;
  align-items: center;
  gap: 8px;
}

.variant-options .form-check-input {
  margin-top: 0;
}

.variant-options .form-check-label {
  margin-bottom: 0;
}