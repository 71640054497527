.subcategories-list {
  list-style: none;
  padding-left: 20px;
  margin: 5px 0;
}

.item-select-tab {
  padding: 8px 15px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.item-select-tab:hover {
  background: #f0f0f0;
  border-radius: 8px;
}

.subcategory-item {
  padding: 8px 15px 8px 30px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
}

.subcategory-item:hover {
  background: #f0f0f0;
  border-radius: 8px;
  color: #000;
}

.subcategory-item.selected-tab-pr {
  background-color: #f0f0f0;
  border-radius: 8px;
  font-weight: 500;
}

.selected-tab-pr {
  background: #f0f0f0;
  border-radius: 8px;
}

.bullet {
  margin-right: 8px;
  font-size: 16px;
  color: #666;
}

.aside-header-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid #eee;
}

.img-close-cart {
  cursor: pointer;
  width: 24px;
  height: 24px;
  margin-left: auto;
}

.title-top-aside {
  margin: 0;
} 