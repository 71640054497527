.subscription-modal .modal-content {
  border-radius: 12px;
  padding: 1rem;
}

.subscription-modal .modal-header {
  padding: 2rem 2rem 0;
  position: relative;
}

.subscription-modal .modal-header .btn-close {
  opacity: 0.5;
  transition: opacity 0.2s;
  z-index: 10;
  filter: none !important;
}

.subscription-modal .modal-header .btn-close:hover,
.subscription-modal .modal-header .btn-close:focus {
  opacity: 0.75;
  filter: none !important;
  background-color: transparent !important;
}

.subscription-modal .form-control:focus {
  border-color: #4a90e2;
  box-shadow: 0 0 0 0.2rem rgba(74, 144, 226, 0.25);
}

.subscription-modal .form-control {
  padding: 0.75rem 1rem;
}

.subscription-modal .btn-primary:hover {
  box-shadow: 0 4px 15px rgba(74, 144, 226, 0.35);
}

.subscription-modal .form-control.is-invalid {
  border-color: #dc3545;
  background-image: none;
}

.subscription-modal .invalid-feedback {
  font-size: 0.875em;
  margin-top: 0.25rem;
  color: #dc3545;
}

@media (max-width: 768px) {
  .subscription-modal .btn-close {
    padding: 1rem !important;
    font-size: 1.8rem !important;
    opacity: 0.75 !important;
    filter: none !important;
  }
  
  .subscription-modal .modal-content {
    margin: 15px;
  }
} 