.client-data-section,
.products-section {
  padding: 15px;
  margin-bottom: 20px;
  border-radius: 8px;
}

.products-section {
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
}

.product-search {
  position: relative;
  margin-bottom: 15px;
}

.search-results {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: white;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
}

.search-result-item {
  padding: 8px 12px;
  cursor: pointer;
}

.search-result-item:hover {
  background-color: #f0f0f0;
}

.selected-product-item {
  background: white;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #dee2e6;
  border-radius: 4px;
}

.product-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.selected-product-item select,
.selected-product-item input {
  margin-bottom: 8px;
}

.error-message {
  color: red;
  margin: 10px 0;
} 