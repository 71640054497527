.card-container-sec {
  background: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
  width: 100%;
  border-radius: 15px;
  height: 250px;
  margin-top: 40px;
  transition: 0.1s;
  cursor: pointer;
}

.card-container-sec:hover {
  transform: scale(0.9);
  transition: 0.1s;
}

.container-decor-top {
  background: #000;
  width: 100%;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  padding: 10px;
}

.log-mark {
  font-size: 18px;
  margin-bottom: 0px !important;
  text-align: center;
  color: #fff;
  opacity: 0.8;
}

.card-inide-pad {
  padding: 20px;
}

.text-top-sec {
  font-size: 30px;
  opacity: 0.8;
}

.text-top-sec-title {
  background: #eee;
  padding: 10px;
  border-radius: 15px;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}
