
  /* Banner */
  .nosotros-banner {
    margin-top: 140px;
    background: url('../images/10.jpg') center center/cover no-repeat;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-align: center;
    position: relative;
  }

  .nosotros-banner::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }
  
  .banner-title {
    font-size: 2.5rem;
    margin: 0;
  }

  .banner-content{
    z-index: 100 !important;
  }
  
  .banner-subtitle {
    font-size: 1.2rem;
    margin-top: 10px;
  }
  
  /* Sección de Información */
  .nosotros-info {
    padding: 50px 0;
    background-color: #f8f8f8;
  }
  
  .info-title {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .info-description {
    text-align: center;
    font-size: 1.1rem;
    margin: 10px 0;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  }
  
  /* Galería de Fotos */
  .nosotros-gallery {
    padding: 50px 0;
  }
  
  .gallery-title {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .swiper-container {
    width: 80%;
    margin: auto;
  }
  
  .gallery-item {
    width: 100%;
    height: auto;
    display: block;
    margin-top: 30px;
  }

  .gallery-item-mayor {
    position: relative !important;
    width: 100%;
    height: 400px;
    object-fit: cover;
    display: block;
    margin-top: 50px;
    border: 5px solid yellow;
    z-index: 1;
  }
  
  .gallery-item-mayor::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 7);
    z-index: 2; 
}

  /* Footer */
  .nosotros-footer {
    background-color: #333;
    color: white;
    padding: 20px 0;
    text-align: center;
  }
  
  .footer-text {
    margin: 0;
  }
  
  .footer-links {
    margin-top: 10px;
  }
  
  .footer-link {
    color: white;
    margin: 0 10px;
    text-decoration: none;
  }
  
  .footer-link:hover {
    text-decoration: underline;
  }
  

  .gallery-item-wrapper {
    position: relative;
    overflow: hidden;
    border-radius: 8px;
  }
  
  .gallery-item-mayor {
    width: 100%;
    height: auto;
    transition: transform 0.3s ease;
  }
  
  .gallery-item-wrapper:hover .gallery-item-mayor {
    transform: scale(1.1);
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s ease;
    z-index: 10000;
  }
  
  .gallery-item-wrapper:hover .overlay {
    opacity: 1;
  }
  
  .btn-contactar {
    margin-top: 10px;
    padding: 8px 16px;
    background-color: yellow;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    color: white;
    font-weight: bold;
  }
  
  .btn-contactar:hover {
    background-color: #fff;
    color:#000; 
  }
  
  

  @media screen and (min-width: 0px) and (max-width: 700px) {
   .banner-title{
    font-size: 30px;
   }
   .info-description{
    font-size: 18px;
   }
  }
  
  .preguntas-frecuentes {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }

  .pregunta-item {
    background: #ffffff;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease;
  }

  .pregunta-item:hover {
    transform: translateY(-2px);
  }

  .pregunta {
    color: #333;
    font-size: 1.2rem;
    margin-bottom: 10px;
    font-weight: 600;
  }

  .respuesta {
    color: #666;
    line-height: 1.6;
    font-size: 1rem;
  }
  