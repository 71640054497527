.container-footer {
  position: relative;
  margin-top: 100px;
  background: #000;
  padding: 80px 50px 30px 50px;
  color: #fff;
}

.title-top-foot {
  font-size: 28px;
  font-weight: bold;
  text-align: center;
}

.col-foot {
  text-align: center;
  margin: 20px 0;
}

.item-foot {
  margin-top: 20px;
  padding: 20px;
  transition: 0.2s;
  text-align: center;
  opacity: 0.36;
  border-radius: 10px;
}

.item-foot:hover {
  background: #fff;
  color: #000;
  cursor: pointer;
  transition: 0.2s;
  opacity: 1;
  border-radius: 10px;
}

.contaiener-iframe {
  margin-top: 40px;
}

.text-important-agency {
  position: relative;
  text-align: center;
  font-size: 20px;
  opacity: 0.6;
  margin-top: 50px;
  padding-bottom: 20px;
  width: 100%;
}

.img-static-w {
  position: fixed;
  width: 60px;
  right: 30px;
  bottom: 30px;
  z-index: 1000;
}

.img-footer-social-media {
  width: 35px;
  margin: 30px 10px;
  cursor: pointer;
}
