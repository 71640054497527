.card-success-cart {
  padding: 24px;
  background: #000;
  color: #fff;
  position: fixed;
  z-index: 1000000000000 !important;
  top: 20px;
  right: 20px;
  box-shadow: 1px 25px 30px rgba(17, 23, 21, 0.33);
  transform: translateY(0%);
  visibility: visible;
  transition: 0.5s;
  opacity: 1;
  border-radius: 9px;
}

.card-success-cart-inactive {
  transform: translateY(-100%);
  visibility: hidden;
  transition: 0.5s;
  opacity: 0;
}

.card-success-cart-chargue {
  padding: 24px;
  background: #fff;
  position: fixed;
  z-index: 100000;
  top: 20px;
  right: 20px;
  box-shadow: 1px 25px 30px rgba(17, 23, 21, 0.33);
  transform: translateY(0%);
  visibility: visible;
  transition: 0.5s;
  opacity: 1;
  border-radius: 9px;
  background-color: #bd00ff;
}

.card-success-cart-inactive-chargue {
  transform: translateY(-100%);
  visibility: hidden;
  transition: 0.5s;
  opacity: 0;
}

.title-card-success-cart {
  font-size: 19px;
  margin-bottom: 0px !important;
  color: #fff;
}

@media screen and (min-width: 0px) and (max-width: 768px) {
  .title-card-success-cart {
    font-size: 16.6px;
  }
}

.banner-section {
  width: 100%;
  position: relative;
  margin-bottom: 2rem;
}

.banner-image {
  width: 100%;
  height: auto;
  max-height: 500px;
  object-fit: cover;
}

.banner-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 2.5rem;
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
  z-index: 1;
}

.banner-text-2 {
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 1.5rem;
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
  z-index: 1;
}

@media (max-width: 768px) {
  .banner-image {
    max-height: 300px;
  }
  
  .banner-text {
    font-size: 1.8rem;
  }
  
  .banner-text-2 {
    font-size: 1.2rem;
  }
}
