a:hover {
  color: #000 !important;
}

a {
  color: #000 !important;
}
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-container {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: #000;
  color: #fff;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
}

h2 {
  margin-top: 0;
}

label {
  display: block;
  margin: 10px 0;
}

input {
  width: 100%;
  padding: 8px;
  margin-top: 4px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.btn-dash {
  background: #000;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 4px;
  margin-top: 10px;
}

button:hover {
  background: #333;
}

.container-general-dash {
  max-width: 100% !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.canvas-dashboard-web {
  height: 100vh;
  padding: 30px 50px 35px 35px;
  background-color: #eee !important;
  overflow-y: scroll;
}

.container-canvas-dashboard-web {
  max-width: 100% !important;
}

.banner-dash {
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to right, #000, #333);
  width: 100%;
  height: 267px;
  border-radius: 15px;
  position: relative !important;
  overflow: hidden;
}

.banner-dash-2 {
  background: linear-gradient(to right, #000, #333);
}

.span-text-banner-date {
  align-items: center;
  background-color: hsla(0, 0%, 100%, 0.3);
  border: 3px solid #fff;
  border-radius: 100px;
  display: flex;
  height: 100px;
  justify-content: center;
  width: 100px;
}

.title-item-banner {
  color: #fff;
  font-size: 23px;
  line-height: 2.1rem;
}

.title-banner-container-text {
  color: #fff;
  margin-right: 40px;
}

.title-banner-span-copy {
  position: absolute;
  bottom: -100px;
  left: 5% !important;
  font-size: 170px;
  font-weight: bold;
  opacity: 0.14;
  color: #fff;
}

.carousel-container {
  width: 100% !important;
  overflow: hidden;
}

.container-title-top-dash-general {
  position: relative;
  padding: 35px 35px 20px 35px;
}

.slick-initialized .slick-slide {
  padding-right: 2.5px !important;
  padding-left: 2.5px;
}

.slick-dots {
  bottom: -36px !important;
}

.slick-prev,
.slick-next {
  display: none !important;
}

.slick-slider {
  overflow: hidden;
  visibility: visible !important;
}

.title-dash-sec {
  margin-top: 50px;
  font-size: 25px;
}

.container-table {
  margin-top: 40px;
  width: 100%;
  height: 450px;
  padding: 35px;
  box-sizing: border-box;
  background-color: #ffffff;
  overflow: auto;
  border-radius: 15px;
  overflow-y: scroll;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table th,
.table td {
  border: 1px solid #dee2e6;
  padding: 20px;
  text-align: left;
}

.table th {
  background-color: #000;
  color: white;
  padding: 10px !important;
}

.table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.table tr:hover {
  background-color: #ddd;
}

/* Estilos para el contenedor de detalles del producto */
.product-details-container {
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-top: 20px;
}

/* Estilos para el detalle del producto */
.product-details {
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.product-details img {
  max-width: 100%;
}

.product-details button {
  margin-top: 20px;
  padding: 10px 20px;
  border: none;
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

.skeleton-animated {
  background: linear-gradient(90deg, #e0e0e0 25%, #dadada 50%, #e2e2e2 75%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
  border-radius: 15px !important;
}

.top-sekeleton {
  margin-top: 20px;
}

@keyframes loading {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: 100% 0%;
  }
}

.modal-backdrop {
  z-index: 1000000 !important;
  backdrop-filter: blur(10px) !important;
  height: 100vh !important;
  overflow-y: scroll !important;
}

.modal {
  z-index: 1000000 !important;
}

.title-prod-similares {
  margin-top: 65px;
}

.details-container {
  background: #fff;
  padding: 35px;
  margin-top: 50px;
  border-radius: 15px;
}

.title-detail-ventas-comp {
  margin-top: 20px;
  margin-bottom: 35px;
}

.btn-sales-comp {
  margin-top: 25px !important;
  padding: 10px 25px !important;
  background: #000 !important;
  color: #fff !important;
  border: none !important;
  margin-right: 20px !important;
}

.container-table-dp {
  margin-top: 50px;
}

.img-pr-dp {
  margin-top: 20px;
  margin-bottom: 20px;
}

.container-web-viewport {
  display: block !important;
}

.product-list-mobile,
.sales-list-mobile {
  margin-top: 40px;
  padding: 40px !important;
  background: #e7e7e7;
  border-radius: 15px;
}

.product-item,
.sale-item {
  background: #fff;
  padding: 20px;
  border-radius: 15px;
}

th {
  background: #000 !important;
  color: #fff !important;
}

td {
  padding: 20px !important;
}

.search-input-ventas-comp {
  width: auto;
  background: #ccc;
  border-radius: 15px;
  margin-right: 10px;
  margin-bottom: 20px;
  padding: 10px 30px;
}

.span-ventas-btn {
  margin-top: 13px !important;
  margin-right: 10px;
}

.btn-add-sale-mobile {
  padding: 12px !important;
  background: #000 !important;
  margin-bottom: 30px;
  width: 100% !important;
}

.card-col-ctn{
  margin-top: 40px;
  background: #fff;
  height:150px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.card-span-finan{
  height: 300px !important;
}

.text-card-col-ctn{
  font-size: 25px;
  opacity: .8;
  margin-bottom: 0px !important;
}

.text-ctn-total-col{
  display:flex ;
  justify-content: center;
  align-items: center;
  margin-bottom: 0px !important;
  font-size: 25px;
  width: 80px;
  height: 80px;
  border: 3px solid #000;
  border-radius: 100%;
}

.span-big{
  width: 100px !important;
  height: 100px !important;
  font-size: 20px;
}



.table td, .table th {
  overflow: hidden;
  text-overflow: ellipsis; 
  white-space: nowrap;
}

.config-comp{
  margin: 50px;
  padding: 40px;
  background: #fff;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
}


.historial-ventas{
  height:300px;
overflow-y: scroll;
  margin-top: 50px;
  padding: 40px;
  background: #fff;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
}

.li-venta-list-data{
  background: #ccc;
  padding: 20px;
  margin-top: 30px;
  border-radius: 15px;
}

.btn-config-texts{
  margin-right: 20px;
  margin-top: 30px;
  background: #000;
  padding: 10px 30px;
  color:#fff;
  border:none !important;
}

.btn-cat-dash{
  margin-right: 15px;
}


.btn-vtn-dash{
  margin-left: 20px;
}

.img-pdf-cont{
  height: 250px !important;
  object-fit: cover;
  border:5px solid #000;
}


.pregunta-item{
  margin-top: 45px;
}

.title-list-pr-comp{
  margin-top: 40px;
}

.input-pr-dash{
  width: 20%;
  margin-left: 20px;
  border-radius: 15px;
  padding: 10px 20px;
}

@media screen and (min-width: 0px) and (max-width: 700px) {
  .container-web-viewport {
    display: none !important;
    width: 100%;
  }
  .container-mobile-viewport {
    display: block !important;
  }

  .span-container-dash {
    padding-top: 15px !important;
  }

  .banner-dash {
    height: 200px !important;
    margin-top: 25px;
    padding: 30px;
    border: none !important;
  }

  .span-container-sales-mobile {
    width: 100% !important;
    overflow: hidden;
    overflow-x: auto !important;
    white-space: nowrap;
  }
  .title-banner-container-text {
    font-size: 22px !important;
  }
  .card-col-ctn{
    background: #e7e7e7;
  }
}

.subcategoria-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  margin: 5px 0;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #eaeaea;
}

.btn-eliminar-sub {
  padding: 5px 15px !important;
  margin-top: 0 !important;
  font-size: 0.9em;
}

.alert {
  position: relative;
  margin: 20px auto;
  padding: 15px 30px;
  border-radius: 4px;
  z-index: 1000;
  animation: slideDown 0.3s ease-out;
  width: fit-content;
  text-align: center;
}

.alert-success {
  background: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

.alert-danger {
  background: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}

@keyframes slideDown {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.categoria-tree-item {
  background: #ffffff;
  border-radius: 12px;
  margin-bottom: 25px;
  padding: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid #eaeaea;
  transition: all 0.3s ease;
}

.categoria-tree-item:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  transform: translateY(-2px);
}

.categoria-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  background: #ffffff;
  border-radius: 8px;
  margin-bottom: 15px;
  border: 1px solid #eaeaea;
}

.categoria-name {
  font-size: 1.2em;
  color: #000;
  font-weight: 500;
}

.tree-view {
  list-style: none;
  padding-left: 25px;
  margin: 15px 0;
}

.tree-item {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 15px;
  margin: 8px 0;
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid #eaeaea;
  transition: all 0.3s ease;
}

.tree-item span {
  display: flex;
  align-items: center;
  color: #444;
}

.sidebar-column {
  transition: all 0.3s ease;
  position: relative;
  background: #fff;
  min-height: 100vh;
  padding: 0;
}

.sidebar-column.expanded {
  width: 250px;
}

.sidebar-column.collapsed {
  width: 80px;
}

.toggle-sidebar-btn {
  position: absolute;
  right: -15px;
  top: 20px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #fff;
  border: 1px solid #ddd;
  cursor: pointer;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
}

.canvas-dashboard-web {
  transition: all 0.3s ease;
}

.canvas-dashboard-web.with-sidebar {
  width: calc(100% - 250px);
}

.canvas-dashboard-web.without-sidebar {
  width: calc(100% - 80px);
}
