.header-mobile {
  display: none !important;
  background: #fff;
  z-index: 100000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 10px 50px rgba(0, 0, 0, 0.1);
}

.title-header-mobile {
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 0px !important;
}

.span-dash {
  left: 15px !important;
  top: 5px !important;
}

@media screen and (min-width: 0px) and (max-width: 700px) {
  .header-mobile {
    display: flex !important;
  }
}

.container-cart-search-mobile {
  display: flex;
  align-items: center;
  gap: 15px;
}

.search-mobile {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.header-mobile-controls {
  display: flex;
  align-items: center;
  gap: 10px;
}

.search-icon-container {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: 60px;
}

.search-container-mobile {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: white;
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  z-index: 1000;
}

.search-input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.search-input-mobile {
  width: 100%;
  padding: 8px 35px 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.close-search-button {
  position: absolute;
  right: 12px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  padding: 2px;
}

/* Ajustes para el dropdown en mobile */
@media (max-width: 768px) {
  .search-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    max-height: 300px;
    z-index: 1000;
    margin: 0;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    background: white;
  }
}

.header-mobile-home-app {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  background: #fff;
  position: relative;
  width: 100%;
  height: 65px;
}

.left-section {
  display: flex;
  align-items: center;
  gap: 15px;
  flex: 1;
}

.profile-brand {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.right-section {
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: flex-end;
}

.search-icon-container {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.card-cart-products {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.img-card-cart-products {
  width: 24px;
  height: 24px;
}

.aside-mobile {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: white;
  z-index: 9999;
  overflow-y: auto;
}
