a {
  text-decoration: none !important;
  color: #000;
}

a:hover {
  text-decoration: none !important;
  color: #fff !important;
}

.conatiner-tab-dash {
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 10000;
  padding: 35px 15px;
  text-align: center;
  position: relative;
}

.selected {
  border-radius: 15px;
  background: #000 !important;
  color: #fff !important;
}

.text-tab-dash-options {
  font-size: 18.5px;
  margin-bottom: 0px !important;
}

.item-tab-dash-options {
  position: relative;
  background-color: #f5f5f5;
  padding: 17.5px;
  border-radius: 15px;
  transition: 0.5s;
  margin-bottom: 35px;
}

.item-tab-dash-options:hover {
  background-color: #000;
  color: #fff;
  cursor: pointer;
  transform: scale(0.96);
  transition: 0.5s;
}

.span-tab-selected {
  background-color: #000;
  color: #fff;
}

.title-top-dash {
  font-size: 25px;
  font-weight: bold;
  color: #000;
}

.container-title-top-dash {
  margin-bottom: 35px;
}

.container-btn-out-tab {
  width: 95%;
  position: absolute;
  bottom: 35px;
}

.btn-out-tab {
  background: #000;
  padding: 12px 0px;
  border-radius: 15px;
  border: none;
  width: 85%;
  color: #fff;
  transition: 0.4s;
}

.btn-out-tab:hover {
  transform: scale(0.9);
  transition: 0.4s;
}

.span-tab-dash {
  top: 0 !important;
}

.tab-dash-container {
  transition: all 0.3s ease;
  overflow: hidden;
}

.tab-dash-container.collapsed .text-tab-dash-options,
.tab-dash-container.collapsed .title-top-dash,
.tab-dash-container.collapsed .btn-out-tab {
  opacity: 0;
  white-space: nowrap;
}

.tab-dash-container.expanded .text-tab-dash-options,
.tab-dash-container.expanded .title-top-dash,
.tab-dash-container.expanded .btn-out-tab {
  opacity: 1;
}

.item-tab-dash-options {
  padding: 15px;
  transition: all 0.3s ease;
  white-space: nowrap;
  overflow: hidden;
}

.text-tab-dash-options {
  transition: opacity 0.3s ease;
  margin: 0;
  font-size: 16px;
}

.title-top-dash {
  transition: opacity 0.3s ease;
  white-space: nowrap;
}

.container-title-top-dash {
  padding: 20px 15px;
}

.btn-out-tab {
  transition: opacity 0.3s ease;
  width: 100%;
  padding: 10px;
  background: #000;
  color: #fff;
  border: none;
  border-radius: 5px;
}

.container-btn-out-tab {
  padding: 15px;
  position: absolute;
  bottom: 20px;
  width: 100%;
}

/* Ajuste para el estado colapsado */
.tab-dash-container.collapsed .item-tab-dash-options {
  padding: 15px 0;
  display: flex;
  justify-content: center;
}

.tab-dash-container.collapsed .container-btn-out-tab {
  padding: 15px 0;
}
