.container-title-one-home {
  width: 100%;
  padding: 50px;
  text-align: center;
}

.title-one-home {
  font-weight: bold !important;
  color: #000;
}

.text-one-home {
  font-size: 18px;
  max-width: 700px;
  margin: 30px auto;
}

.container-banner-med-home {
  display: flex;
  justify-content: center;
  align-items: center;
  background: url('../images/4.jpg') center center;
  background-size: cover;
  width: 100%;
  height: 350px;
}

.container-items-med-home {
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
  text-align: center;
  padding: 35px;
  color: #fff;
}

.btn-sec-pr-all {
  padding: 10px 45px;
  background: #000;
  color: #fff;
  border: none !important;
}

.swiper-button-next,
.swiper-button-prev {
  color: #fff !important;
  background: #000;
  padding: 30px;
  border-radius: 15px;
  top: 46% !important;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  font-size: 20px !important;
}

.swiper-scrollbar-drag {
  display: none !important;
}

.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  white-space: nowrap !important;
  overflow: hidden !important;
}

.swiper-pagination-bullets .swiper-pagination-bullet:nth-child(n+21) {
  display: none !important;
}

.swiper{
  padding: 30px 0px;
}

.swiper-pagination-bullet-active {
  background: #000 !important;
}

.card-swiper {
  padding: 0px 5px !important;
}

.section-swiper-pr {
  margin-top: 50px;
}

.header-mobile-home-app {
  display: none;
}

.span-header-top-mobile {
  left: 20px !important;
}

.aside-header {
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  background: #fff;
  z-index: 15000;
  height: 100vh;
  overflow-y: scroll;
  scrollbar-width: thin;
  padding: 85px 50px;
  visibility: hidden;
  opacity: 0;
  transform: translateX(-100%);
  transition: 0.3s;
  z-index: 1000001 !important;
}

.aside-header-active {
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  background: #fff;
  z-index: 15000;
  height: 100vh;
  overflow-y: scroll;
  scrollbar-width: thin;
  padding: 85px 50px;
  visibility: visible;
  opacity: 1;
  transform: translateX(0%);
  transition: 0.3s;
  z-index: 1000001 !important;
}

.item-aside-header {
  font-size: 20px;
  padding: 20px;
  border: none !important;
  transition: 0.3s;
}

.item-aside-header:hover {
  background: #000;
  color: #fff;
  transition: 0.3s;
}

.title-top-aside {
  font-size: 26px;
  font-weight: bold;
}

.banner-top-home {
  width: 100%;
  height: 400px;
  margin-top: 140px;
  margin-bottom: 50px;
}

.banner-img-inside::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1;
  transition: 2s;
  transition: transform 2s, background-color 2s;
}

.banner-img-inside {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

.text-img-inside {
  font-size: 100px;
  font-family: 'Bebas Neue', sans-serif;
  font-weight: bold;
  color: #fff;
  z-index: 2;
  cursor: pointer;
  text-align: center;
}

.title-trend-home {
  font-family: 'Bebas Neue', sans-serif;
  font-size: 45px;
  font-weight: bold;
  color: #000;
  margin-bottom: 25px;
}

.container-text-trend-home {
  width: 100%;
  text-align: center;
  margin-bottom: 50px;
}

.btn-title-trend-home {
  background: #000;
  padding: 10px 65px;
  color: #fff;
  border: none;
}

.swiper-horizontal > .swiper-scrollbar,
.swiper-scrollbar.swiper-scrollbar-horizontal {
  display: none !important;
}

.sectiom-swiper-home {
  margin-bottom: 50px;
}

.poster-1 {
  height: 400px;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-bottom: 30px;
}

.poster-1:hover {
  transform: scale(1.01);
}

.text-poster {
  color: white;
  font-size: 36px;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.span-subs-home{
  padding: 40px !important;
}

.span-subs-btn-mobile-home{
  margin-top: 30px !important;
}

.modal.show .modal-dialog{
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 0px) and (max-width: 700px) {
  .header-mobile-home-app {
    position: fixed;
    top: 45px;
    left: 0;
    width: 100%;
    height: 75px;
    padding: 20px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background: #fff;
    z-index: 10000;
    box-shadow: 0 10px 50px rgba(0, 0, 0, 0.1);
  }
  .header-general-products {
    display: none !important;
  }
  .header-top-banner-products {
    padding: 20px;
    text-align: center;
  }

  .text-inside-banner-products {
    font-size: 12px;
  }

  .text-important-agency {
    font-size: 17px !important;
    left: 2% !important;
  }

  .col-foot {
    margin-top: 30px;
  }

  .text-one-home {
    font-size: 14px;
  }
  .title-one-home {
    font-size: 20px;
  }
  .carousel-caption p {
    font-size: 14px !important;
    opacity: 0.8;
  }

  .title-banner-med {
    font-size: 20px;
  }
  .text-banner-med {
    font-size: 15px;
    opacity: 0.8;
  }
  .text-img-inside {
    font-size: 40px;
    padding: 0px 30px;
  }
  .swiper-button-next,
  .swiper-button-prev {
    padding: 22px;
    top: 38% !important;
  }
  .span-subs-mobile-home{
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    margin: auto !important;
  }
}

.option-header {
  white-space: nowrap;
  padding: 0 15px;
  cursor: pointer;
  transition: 0.3s;
}

.option-header:hover {
  background: #f0f0f0;
  border-radius: 8px;
  transition: 0.3s;
}

.container-row-header-options {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  list-style: none;
  margin: 0;
  padding: 0;
}

.img-static-w {
  transition: transform 0.2s ease;
}

.img-static-w:hover {
  transform: scale(1.1);
}

@media (max-width: 768px) {
  .banner-top-home {
    height: 300px;
  }

  .text-img-inside {
    font-size: 1.8rem;
  }
}

.poster-category {
  height: 400px;
  margin: 15px 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: transform 0.3s ease;
  border-radius: 8px;
  position: relative;
}

.poster-category::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 8px;
}

.poster-category:hover {
  transform: scale(1.02);
}

.text-poster {
  color: white;
  font-size: 48px;
  font-weight: bold;
  text-shadow: 3px 3px 6px rgba(0,0,0,0.7);
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 2px;
  position: relative;
  z-index: 1;
}

@media screen and (max-width: 768px) {
  .poster-category {
    height: 250px;
  }
  
  .text-poster {
    font-size: 32px;
  }
}
