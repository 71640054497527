body {
  width: 100%;
  height: 100vh;
  overflow-y: none !important;
}

.login-container-dashboard {
  overflow-y: hidden !important;
  position: relative;
}

.input-wel {
  width: 100% !important;
  margin-bottom: 30px;
  padding: 20px 40px;
  border: none;
  background-color: #eee;
  border-radius: 100px;
  font-size: 17px;
  font-family: 'Poppins', sans-serif;
}

.span-input {
  flex-direction: column;
  margin: auto;
}

input:focus {
  border: 1px solid yellow;
}

.span-login {
  font-size: 17px;
  font-family: 'Poppins', sans-serif;
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

@media only screen and (max-width: 1060px) {
  .btn-welcome {
    width: 120% !important;
  }
}

body {
  font-family: 'poppins', sans-serif !important;
}

.container-welcome {
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  align-items: space-between;
  margin: 30px;
  text-align: center;
  font-family: 'Poppins', sans-serif;
}
.img-welcome {
  width: 90%;
}

.item {
  flex: 1;
  display: inline-flex; /* Cambiado a inline-flex para ajustarse al contenido */
  align-items: center; /* Centrar contenido horizontalmente */
  justify-content: center; /* Centrar contenido verticalmente */
}

.title-welcome {
  font-size: 35px;
  line-height: 3rem;
  font-weight: bolder;
}

.text-welcome {
  font-size: 18px;
  line-height: 2rem;
  width: 90%;
  color: #646464;
}

.btn-welcome {
  background: #000;
  padding: 16.3px 0px;
  border-radius: 100px;
  border: none;
  color: #fff;
  text-decoration: none;
}

.item-span-welcome-screen {
  color: #000;
}

.container-mobile {
  display: none !important;
}

.container-web {
  max-width: 100% !important;
}

.item-welcome {
  padding-left: 0px !important;
  padding-right: 0px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh !important;
}

.img-welcome {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
}

.title-welcome-dash,
.text-welcome-dash {
  margin-bottom: 25px;
  text-align: center;
}

.btn-welcome-dash {
  background-color: linear-gradient(to right #e44d26, #f16529);
  color: #fff;
  text-decoration: none;
  padding: 12px 45px;
  border-radius: 100px;
  transition: 0.5s;
}

.btn-welcome-dash:hover {
  color: #fff;
  transform: scale(0.9);
  transition: 0.5s;
}

.text-welcome-dash {
  color: #5f5f5f;
}

.span-welcome-dash {
  color: #f16529 !important;
}

.img-welcome-logo {
  position: absolute;
  width: 160px;
  top: 37%;
  left: 44%;
  z-index: 1000;
}

input[type='text']:focus,
input[type='password']:focus {
  border: 1px solid #000 !important;
  outline: none;
  transform: scale(0.95);
  transition: 0.1s;
}

input {
  transition: 0.5s !important;
}
