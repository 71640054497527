.search-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: white;
  border: 1px solid #ddd;
  border-radius: 0 0 8px 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  z-index: 1000;
  max-height: 400px;
  overflow-y: auto;
  width: 100%;
  margin-top: -1px;
}

.search-item {
  display: flex;
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #eee;
  transition: background-color 0.2s;
  align-items: center;
}

.search-item:last-child {
  border-bottom: none;
}

.search-item:hover {
  background-color: #f8f8f8;
}

.search-item-image {
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin-right: 10px;
  border-radius: 6px;
  border: 1px solid #eee;
}

.search-item-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
}

.search-item-name {
  font-size: 14px;
  color: #333;
  font-weight: 500;
  margin-bottom: 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.search-item-price {
  font-size: 12px;
  color: #2c5282;
  font-weight: 600;
  margin-top: 4px;
} 