.img-detalle-producto-ds-small {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 100px;
}

.span-detalle-pr-img {
  left: 15px !important;
  top: 15px !important;
}

.container-img-detalle {
  border-radius: 15px;
  position: relative;
  width: 510px;
  height: 450px;
  overflow: hidden;
  margin-left: 20px;
  border: 2px solid yellow;
}

.img-detalle-producto {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.container-thumbnails {
  position: absolute;
  bottom: 0; /* O ajusta esto según dónde quieras que aparezca el contenedor de miniaturas */
  left: 0;
  width: 100%;
  white-space: nowrap; /* Mantiene las miniaturas en una sola línea horizontal */
  overflow-x: auto; /* Habilita el desplazamiento horizontal si las miniaturas son más anchas que el contenedor */
  overflow-y: hidden; /* Oculta cualquier desbordamiento vertical */
  padding-right: 40px; /* Ajusta el relleno derecho si es necesario */
  box-shadow: 0 -4px 60px rgba(0, 0, 0, 0.1); /* Opcional: sombra para mejorar la visibilidad */
}

.span-container-rows-detalle-pr {
  margin-top: 20px;
}

.span-detalle-pr {
  margin-top: 30px;
}

.text-type-detalle {
  font-size: 25px;
  opacity: 0.7;
}

.price-detalle-pr {
  font-size: 25px;
}

.text-description-detalle-pr {
  width: 85%;
  padding: 20px;
  border-radius: 15px;
  background: #fff;
}

.text-description-detalle-pr {
  line-height: 1.9rem;
}

.span-item-detalle-pr {
  background: #fff !important;
  right: 80px;
}

.btn-options-detail-pr {
  margin-top: 20px;
  padding: 10px 25px;
  background: #000;
  color: #fff;
  border: none !important;
}

.btn-delete-options {
  padding: 10px 25px;
  background: #000;
  color: #fff;
  border: none !important;
  margin-top: 15px;
}

.cant-input-dtp {
  margin-top: 20px;
}

.option-drop-dp {
  padding: 10px 25px;
  background: #000;
  color: #fff;
  border: none !important;
  margin-top: 15px;
  margin-right: 20px;
}

.container-detail {
  padding: 0px !important;
}

.container-img-detail {
  width: 100%;
  height: 300px;
  overflow: hidden;
  position: relative;
}

.container-img-detail::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.37);
  z-index: 1;
}

.img-detail {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.text-detail-top {
  position: absolute;
  font-size: 20px;
  color: #fff;
  font-weight: bold;
  top: 20px;
  left: 25%;
  z-index: 2;
}

.span-detalle-pr-img {
  z-index: 2;
}

.container-title-art {
  margin-top: 30px;
}

.title-detail-art {
  font-size: 30px;
  font-weight: bold;
}

.container-description-art {
  margin-top: 30px;
}

.container-detail {
  position: relative;
  padding-bottom: 120px !important;
  padding-top: 60px !important;
}

.container-items-price-cart {
  padding: 15px;
  width: 100%;
  bottom: 0px;
  left: 0px;
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  box-shadow: 0 -4px 60px rgba(0, 0, 0, 0.1);
  z-index: 100000 !important;
}

.mobile-buttons-container {
  display: flex;
  gap: 10px;
}

.cart-btn-detail {
  border-radius: 15px;
  margin-top: 3px;
  padding: 10px 25px;
  background: #000;
  color: #fff;
  border: none !important;
  font-size: 14px;
  transition: all 0.3s ease;
}

.cart-btn-detail.btn-back {
  background: #333;
}

.cart-btn-detail:active {
  transform: scale(0.95);
}

.price-detail {
  font-weight: bold;
  font-size: 30px;  /* Reducimos un poco el tamaño para dar más espacio */
  margin: 0;
}

.container-flex-art {
  display: flex;
  gap: 30px;
  margin-top: 30px;
}

.color-item,
.talla-item {
  padding: 10px;
  background: #f5f5f5;
  border-radius: 15px;
  cursor: pointer;
  margin-bottom: 15px;
}

.selected-color,
.selected-size {
  background: #000 !important;
  color: #fff;
}

.btn-add-qt {
  background: #000;
  color: #fff;
  border: none !important;
  padding: 4px 11px;
  margin: 5px 10px;
  margin-bottom: 30px !important;
  border-radius: 100px;
}

.container-thumbnails {
  width: 100%;
  bottom: 20px;
  white-space: nowrap;
  overflow-x: scroll;
  scroll-behavior: smooth;
  cursor: grab;
  left: 20px;
  position: absolute;
  padding-right: 40px;
  gap: 15px;
  z-index: 1000 !important;
}

.container-thumbnails::-webkit-scrollbar {
  display: none !important;
}

.thumbnail {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border: 1px solid #000;
  border-radius: 15px;
  cursor: pointer;
  margin-right: 10px;
}

.container-thumbnails:active {
  cursor: grabbing;
}

.drop-detalle-pr {
  background: #fff !important;
  padding: 8px 5px !important;
  border-radius: 15px !important;
  border: none !important;
}

.drop-detalle-pr:disabled {
  background-color: #f5f5f5 !important;
  cursor: not-allowed;
  opacity: 0.7;
}

.drop-detalle-pr option:disabled {
  color: #999;
  font-style: italic;
}

/* Estilo para opciones sin stock */
.drop-detalle-pr option[disabled] {
  color: #999;
  font-style: italic;
}

.title-pr-bottom-plus {
  margin: 40px 20px;
}

.title-product-section-aticle {
  font-size: 25px;
}

.container-items-scroll {
  position: relative;
}

.cantidad-container-mobile {
  position: absolute;
  right: 10px;
  top: 45px;
}

.span-detail-header {
  top: 0 !important;
}

.banner-tag-container-img-pr-web {
  font-size: 16px;
  padding: 10px 20px;
  z-index: 5;
  top: 25px;
  right: 0px;
  position: absolute;
  background: #000;
  color: #fff;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

.item-dect-pr-detail {
  z-index: 1000;
  position: absolute;
  background: #000;
  color: #fff;
  top: 50px;
  font-size: 20px;
  padding: 10px 30px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

.container-items-share {
  padding: 20px;
  z-index: 1000;
  position: absolute;
  top: 30%;
  right: 0px;
}

.img-items-share {
  width: 26px;
  margin-bottom: 20px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000000;
}

.modal-content {
  position: relative;
  max-width: 90%;
  max-height: 90%;
}

.modal-image {
  width: 100%;
  height: auto;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #000;
  color: #fff;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}

.thumbnail-video-container {
  display: inline-block;
  width: 80px;
  height: 80px;
  object-fit: cover;
  border: 1px solid #000;
  border-radius: 15px;
  cursor: pointer;
  margin-right: 10px;
  overflow: hidden;
  position: relative;
  vertical-align: top; /* Esto alineará el video con las imágenes */
}

.thumbnail-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.thumbnail-youtube {
  width: 100%;
  height: 100%;
  background: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-size: cover;
  background-position: center;
}

.thumbnail-youtube::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
}

.youtube-play-icon {
  width: 30px;
  height: 30px;
  opacity: 0.8;
  transition: opacity 0.3s ease;
  position: relative;
  z-index: 1;
}

.thumbnail-youtube:hover .youtube-play-icon {
  opacity: 1;
}

.thumbnail-youtube:hover::before {
  background: rgba(0, 0, 0, 0.2);
}

.video-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000000;
}

.video-modal-content {
  position: relative;
  background: #000;
  padding: 6px;
  border-radius: 4px;
  width: 90%;
  max-width: 800px;
}

.expanded-video {
  width: 100%;
  aspect-ratio: 16/9;
  border: none;
}

.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #000;
  color: #fff;
  border: none;
  padding: 10px;
  cursor: pointer;
}

.tallas, .colores{
  white-space: nowrap;
  overflow: hidden;
  overflow-x:auto;
}

.tallas::-webkit-scrollbar, .colores::-webkit-scrollbar{
  display: none !important;
}

.skeleton-animated {
  background: linear-gradient(90deg, #e0e0e0 25%, #dadada 50%, #e2e2e2 75%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
  border-radius: 15px !important;
}


.btn-shop-pr-dt{
  padding: 10px;
  width: 80% !important;
  margin-top: 20px;
  background: #000;
  color:#fff;
  border:none !important;
}


.container-items-auto-img-dt-pr{
  display: flex;
  position: absolute;
  top: 60px;
  right: 20px;
  z-index: 10;
}

.span-stars-web{
  width: 80px;
  height:30px;
  background: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
}

.img-items-auto-img-dt-pr{
  width: 20px;
  height: 20px;
  margin-right:5px;
}

.text-items-auto-img-dt-pr{
  font-weight: bold;
  color:#fff;
  margin-bottom: 0px !important;
}

.span-item-detail-punt{
  margin-top: 20px !important;
}

@media screen and (min-width: 0px) and (max-width: 700px) {
  .text-description-detalle-pr {
    background: #f5f5f5;
    width: 100%;
  }
  .container-flex-art {
    display: block;
  }
  .color-item,
  .talla-item {
    display: inline-block;
    width: auto;
    padding: 10px 40px;
    margin-right: 20px;
  }

  .title-product-section-aticle {
    font-size: 18px;
  }
  .text-tag-pr {
    font-size: 14.6px;
  }
  .card-cart-products {
    right: 6px !important;
  }
  .banner-tag-container-img {
    font-size: 14.5px;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1200px) {
  .container-img-detalle {
    width: 350px;
    height: 350px;
  }
  .text-description-detalle-pr {
    font-size: 13px;
  }
  .color-item,
  .talla-item {
    font-size: 10px;
  }
}

@media screen and (min-width: 700px) and (max-width: 1000px) {
  .option-span-mobile {
    display: none;
  }
  .container-img-detalle {
    width: 100%;
  }
  .spanquery {
    margin-top: 50px !important;
  }
}

.stock-info {
  background: #f8f9fa;
  padding: 10px 15px;
  border-radius: 8px;
  margin: 10px 0;
  font-size: 0.9em;
  color: #666;
}

.stock-info p {
  margin: 0;
}

/* Para la vista móvil */
@media screen and (max-width: 700px) {
  .colores .color-item,
  .tallas .talla-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
  }

  .stock-info {
    margin: 15px 0;
    text-align: center;
  }
}

/* Estilos para opciones deshabilitadas en móvil */
.disabled-option {
  opacity: 0.5;
  cursor: not-allowed;
  background: #f0f0f0;
}

.talla-message {
  padding: 10px;
  color: #666;
  font-style: italic;
  text-align: center;
  background: #f8f9fa;
  border-radius: 8px;
  margin: 10px 0;
}

/* Ajustes para móvil */
@media screen and (max-width: 700px) {
  .color-item,
  .talla-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 15px;
    margin: 5px 0;
    background: #fff;
    border: 1px solid #eaeaea;
    border-radius: 8px;
  }

  .disabled-option {
    border: 1px solid #eaeaea;
  }

  .selected-color,
  .selected-size {
    background: #000;
    color: #fff;
    border-color: #000;
  }

  .stock-info {
    background: #f8f9fa;
    padding: 15px;
    border-radius: 8px;
    margin: 15px 0;
    text-align: center;
  }
}

.back-button-mobile {
  position: fixed;
  top: 80px;
  left: 15px;
  z-index: 1000;
  display: flex;
  align-items: center;
  gap: 8px;
  background: #000;
  color: white;
  padding: 8px 15px;
  border-radius: 20px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
}

.back-icon {
  width: 20px;
  height: 20px;
  filter: invert(1); /* Para hacer el ícono blanco */
}

@media screen and (min-width: 701px) {
  .back-button-mobile {
    display: none;
  }
}

@media screen and (min-width: 701px) {
  .mobile-buttons-container .btn-back {
    display: none;
  }
}

/* Ajustamos los estilos para el nuevo layout sin sidebar */
.span-canvas-products {
  padding: 170px 35px 50px !important;
  overflow-y: scroll;
  height: 100vh;
}

.card-cart-products.span-item-card-product.span-detalle-pr-img {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 10;
  background: #000;
  transition: all 0.3s ease;
}

.card-cart-products.span-item-card-product.span-detalle-pr-img:hover {
  transform: scale(1.05);
}

/* Aseguramos que el botón de volver sea visible en móvil */
@media screen and (max-width: 700px) {
  .mobile-buttons-container {
    display: flex;
    gap: 10px;
  }

  .cart-btn-detail.btn-back {
    background: #333;
  }
}

.back-button-detail {
  position: absolute;
  top: 20px;
  left: 20px;
  background: rgba(255, 255, 255, 0.9);
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  transition: all 0.3s ease;
}

.back-button-detail:hover {
  background: rgba(255, 255, 255, 1);
  transform: scale(1.05);
}

.back-button-detail img {
  width: 20px;
  height: 20px;
}

.cantidad-control {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f5f5f5;
  border-radius: 8px;
  padding: 5px;
  width: 120px; /* Ancho fijo para el contenedor */
}

.cantidad-control span {
  min-width: 30px; /* Ancho mínimo para el número */
  text-align: center;
  font-size: 16px;
}

.btn-add-qt {
  width: 30px; /* Ancho fijo para los botones */
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 !important; /* Eliminar márgenes que causan saltos */
  padding: 0 !important;
  border-radius: 50%;
}

/* Añade o modifica estos estilos para el slider lateral */
.aside-mobile {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: white; /* Fondo sólido blanco */
  z-index: 9999; /* Un z-index alto para asegurar que esté por encima de todo */
  overflow-y: auto;
}

/* Asegúrate que el contenedor principal tenga un z-index menor */
.container-mobile-viewport {
  position: relative;
  z-index: 1;
}
