body {
  scrollbar-width: thin !important;
  font-family: 'Poppins', sans-serif !important;
}

html::-webkit-scrollbar,
body::-webkit-scrollbar {
  display: none !important;
}

.header-top-banner-products {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #000;
  z-index: 100000 !important;
}

.text-inside-banner-products {
  font-size: 15px;
  color: #fff;
  margin-bottom: 0px !important;
}

.header-general-products {
  padding: 0px 20px;
  width: 100%;
  position: fixed;
  top: 45px;
  display: flex;
  align-items: center;
  left: 0;
  height: 75px;
  background: #fff;
  box-shadow: 0 10px 50px rgba(0, 0, 0, 0.1);
  z-index: 10000 !important;
}

.profile-brand {
  position: relative;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.img-ptofile-brand {
  width: 60px;
}

.card-cart-products {
  position: absolute;
  top: 12px;
  right: 20px;
  width: 50px;
  height: 50px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e7e7e7;
  cursor: pointer;
}

.img-card-cart-products {
  width: 20px;
}

.tab-left-products-land {
  width: 100%;
  height: 100vh;
}

.span-canvas-products {
  background: #e7e7e7;
}

.span-tab-products {
  padding: 0px !important;
}

.tab-left-products-land {
  padding: 150px 20px 20px;
}

.title-tab-left {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 25px;
}

.item-select-tab {
  font-size: 18px;
  margin-top: 10px;
  cursor: pointer;
  padding: 15px;
  transition: 0.3s;
}

.item-select-tab:hover {
  background: #e7e7e7;
  transition: 0.3s;
}

.span-canvas-products {
  padding: 170px 35px 50px !important;
  overflow-y: scroll;
  height: 100vh;
}

.span-canvas-products::-webkit-scrollbar {
  display: none !important;
}

.text-search {
  font-size: 30px;
  margin-bottom: 40px;
}

.container-img-relative-things {
  position: relative;
  width: 100%;
  height: 270px;
  border-radius: 15px;
  overflow: hidden;
  transition: 0.3s;
  border: 2px solid yellow !important;
}

.banner-tag-container-img {
  font-size: 16px;
  padding: 10px 20px;
  z-index: 5;
  top: 15px;
  left: 0px;
  position: absolute;
  background: #000;
  color: #fff;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

.img-products-section-article {
  width: 100%;
  height: 270px;
  object-fit: cover;
  border-radius: 15px;
  transition: 0.3s;
}

.img-products-section-article:hover {
  transform: scale(1.08);
  transition: 0.3s;
}

.card-products {
  margin-top: 20px !important;
}

.title-product-section-aticle {
  opacity: 0.9;
  font-size: 30px;
  font-weight: bold;
  margin-top: 8px;
}

.container-row-header-options {
  display: flex;
  padding-left: 5%;
  transition: 0.3s;
}

.option-header {
  list-style: none !important;
  padding: 9px 25px;
  font-size: 18px;
  cursor: pointer;
  transition: 0.3s;
}

.span-option-header {
  font-weight: bold;
  background: #e7e7e7;
  border-radius: 15px;
}

.text-product-section-aticle {
  margin-top: 10px;
  font-size: 20px;
  font-weight: bold;
}

.input-header-search-products {
  margin-left: 43px;
  width: 350px;
  padding: 9px 30px;
  background: #e7e7e7;
  border: none !important;
  border-radius: 15px;
}

.span-item-card-product:hover {
  background: #c0c0c0;
  transition: 0.3s;
}

.span-item-card-product {
  z-index: 100 !important;
}

.option-header:hover {
  font-weight: bold;
  background: #e7e7e7;
  border-radius: 15px;
  transition: 0.3s;
}

.selected-tab-pr {
  background: #e7e7e7 !important;
  font-weight: bold;
  border-radius: 15px;
  transition: 0.3s;
}

.skeleton-animated {
  background: linear-gradient(90deg, #e0e0e0 25%, #dadada 50%, #e2e2e2 75%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
  border-radius: 15px !important;
}

.top-sekeleton {
  margin-top: 20px;
}

@keyframes loading {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: 100% 0%;
  }
}

.container-mobile-viewport {
  display: none !important;
}

.container-web-viewport {
  display: flex !important;
}

.title-top-mobile {
  font-size: 20px;
  font-weight: bold;
}

.container-mobile-viewport {
  padding-top: 80px;
  padding-bottom: 30px;
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.banner-top-trend {
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #000;
  height: 50px;
  z-index: 100000 !important;
}

.text-trend-top {
  font-size: 17px;
  color: #fff;
  margin-bottom: 0px !important;
  font-weight: bold;
}

.span-pr-mobile {
  top: 70px !important;
}

.mobile-span-cart {
  right: 20px !important;
}

.container-text-cat-mobile {
  width: 100%;
  margin-top: 40px;
}

.container-text-cat-mobile h1 {
  display: inline;
  font-size: 25px;
}

.title-cat-mobile-right {
  float: right;
  opacity: 0.7;
  font-weight: bold;
}

.title-cat-mobile-left {
  font-weight: bold;
}

.col-6 {
  padding: 0px 7.5px !important;
}

.container-fluid-mobile {
  padding: 0px !important;
}

.container-pr-mobile-span {
  padding-top: 120px !important;
}

.text-tag-pr {
  margin-top: 10px;
  font-weight: bold;
  color: rgb(119, 119, 0);
  margin-bottom: 0px !important;
}

.text-price-pr {
  color: #000;
  font-weight: bold;
}

.text-span-pr-sec{
  display: inline-block;
  background: #000;
  color:#fff;
  border-radius: 15px;
  font-size: 20px;
  padding: 10px 30px;
  margin-left: 8px;
}

.container-row-flex-cat-pr{
  display: flex;
  gap: 10px;
  overflow-x: auto;
  white-space: nowrap;
}

.container-row-flex-cat-pr::-webkit-scrollbar{
  display: none !important;
}

.item-cat--pr{
  opacity: .8;
  font-size: 20px;
  padding: 10px 30px;
  background: #ccc;
  border-radius: 15px;
}

.selected-tab-pr-cat{
  background: #000;
  color:#fff;
  opacity: 1;
  padding: 10px 30px;
  border-radius: 15px;
}

.span-pr-margin-mobile{
  padding: 10px !important;
}

@media screen and (min-width: 0px) and (max-width: 700px) {
  .container-web-viewport {
    display: none !important;
    width: 100%;
  }
  .container-mobile-viewport {
    display: block !important;
  }

  .banner-dash {
    height: 200px !important;
    margin-top: 25px;
    padding: 30px;
    border: 5px solid yellow;
  }
  .title-banner-container-text {
    font-size: 25px !important;
  }
  .title-banner-span-copy {
    font-size: 80px !important;
    bottom: -40px !important;
  }
  .img-products-section-article {
    height: 185px;
  }
  .container-img-relative-things {
    height: 185px;
  }
  .row-mobile {
    width: 100%;
    margin: auto;
  }
  .title-product-section-aticle {
    font-size: 21px;
    font-weight: bold;
  }

  .card-products {
    margin-top: 15px !important;
  }

  .container-fluid-mobile {
    margin-top: 20px;
  }
  .span-text-banner-date {
    width: 85px !important;
    height: 85px !important;
  }

  .title-banner-container-text {
    margin-right: 15px !important;
  }
  .carrito-active,
  .carrito {
    width: 100% !important;
  }
  .text-price-pr {
    display: none;
  }
}

/* Estilos para la vista móvil de categorías */
.mobile-categories-container {
  margin: 20px 0;
  padding: 0 15px;
}

@media screen and (max-width: 700px) {
  .mobile-categories-container .tab-left-products-land {
    background: #fff;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    height: auto;
    min-height: 100px;
    max-height: calc(100vh - 300px);
    overflow-y: auto;
  }

  .mobile-categories-container .title-tab-left {
    font-size: 20px;
    margin-bottom: 15px;
  }

  .mobile-categories-container .item-select-tab {
    font-size: 16px;
    padding: 12px;
    margin: 5px 0;
    border-radius: 8px;
  }

  .mobile-categories-container .subcategories-list {
    margin-left: 15px;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .mobile-categories-container .subcategory-item {
    font-size: 14px;
    padding: 8px 12px;
    margin: 3px 0;
    border-radius: 6px;
  }

  .aside-header-top {
    position: relative;
    padding-right: 40px;
  }

  .img-close-cart {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    padding: 5px;
    background: #f0f0f0;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.2s ease;
  }

  .img-close-cart:hover {
    background: #e0e0e0;
  }

  .aside-header {
    padding: 20px;
    width: 85%;
  }

  .aside-header-content {
    padding-bottom: 50px;
  }

  .title-top-aside {
    font-size: 20px;
  }

  .item-aside-header {
    font-size: 16px;
    padding: 12px 15px;
    margin: 8px 0;
  }
}

/* Estilos para el sidebar móvil */
.aside-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 100000;
}

.aside-header {
  position: fixed;
  top: 0;
  left: -100%;
  width: 80%;
  height: 100vh;
  background: #fff;
  z-index: 100001;
  transition: all 0.3s ease;
  padding: 20px;
}

.aside-header-active {
  left: 0;
}

.aside-header-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.img-close-cart {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.aside-header-content {
  height: 100%;
  overflow-y: auto;
}

.title-top-aside {
  font-size: 24px;
  font-weight: bold;
  margin: 0;
}

.item-aside-header {
  padding: 15px;
  margin: 5px 0;
  cursor: pointer;
  transition: all 0.2s ease;
  border-radius: 8px;
}

.item-aside-header:hover {
  background: #f0f0f0;
}

.category-filter-group {
  display: inline-block;
  margin-right: 10px;
  position: relative;
}

.subcategory-buttons {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  background: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
  margin-top: 5px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.btn-subcategory {
  display: block;
  width: 100%;
  text-align: left;
  margin: 2px 0;
  font-size: 0.9em;
}

.table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  margin: 20px 0;
}

.table th, .table td {
  border: none;
  background: white;
}

.table tbody tr:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}

.button-container {
  padding: 20px;
  margin-bottom: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
}

.btn-cat-dash {
  background: #f8f9fa;
  border: 1px solid #ddd;
  color: #333;
  transition: all 0.2s ease;
}

.btn-cat-dash:hover {
  background: #e9ecef;
  border-color: #ccc;
}

.input-pr-dash {
  padding: 8px 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-left: auto;
  min-width: 200px;
}

.category-filter-group {
  position: relative;
}

.category-filter-group .subcategory-buttons {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
  z-index: 1000;
  min-width: 150px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.category-filter-group:hover .subcategory-buttons {
  display: block;
}

.btn-subcategory {
  width: 100%;
  text-align: left;
  padding: 8px 15px;
  border: none;
  background: none;
  transition: all 0.2s ease;
}

.btn-subcategory:hover {
  background: #f8f9fa;
}

.color-dots {
  display: flex;
  align-items: center;
  gap: 4px;
  flex-wrap: wrap;
}

.color-text {
  font-size: 12px;
  color: #666;
  margin-left: 4px;
}

.size-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}

.no-properties {
  color: #999;
  font-style: italic;
}

/* Ajustamos el ancho de las columnas para mejor visualización */
.table th:nth-child(4), /* Columna de colores */
.table th:nth-child(5) { /* Columna de talles */
  min-width: 150px;
}

.table td {
  vertical-align: middle;
}

.category-filters {
  display: flex;
  gap: 10px;
  margin: 0 10px;
}

.form-select {
  min-width: 200px;
}

.button-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}
