.loading-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999999; 
    transition: opacity 0.5s ease-in-out;
  }