.carrito {
  top: 0;
  right: 0;
  position: fixed;
  width: 36%;
  background: #fff;
  z-index: 15000;
  height: 100vh;
  overflow-y: scroll;
  scrollbar-width: thin;
  padding: 45px 30px;
  visibility: hidden;
  opacity: 0;
  transform: translateX(100%);
  transition: 0.3s;
  z-index: 1000001 !important;
}

.carrito-active {
  top: 0;
  right: 0;
  position: fixed;
  width: 36%;
  background: #fff;
  z-index: 15000;
  height: 100vh;
  overflow-y: scroll;
  scrollbar-width: thin;
  padding: 45px 30px;
  visibility: visible;
  opacity: 1;
  transform: translateX(0%);
  transition: 0.3s;
  z-index: 1000001 !important;
}

.carrito-item {
  position: relative;
  padding: 25px;
  margin-top: 30px;
  width: 100%;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.12);
  border-radius: 15px;
}

.title-cart-general {
  font-size: 25px;
  text-align: center;
}

.text-name-pr {
  font-size: 19px;
  opacity: 0.8;
}

.title-name-pr {
  font-size: 22px;
}

.btn-cart-delete {
  background: #000;
  border: none !important;
  padding: 7px 20px;
  color: #fff;
  position: absolute;
  right: 20px;
  bottom: 20px;
}

.btn-finalizar-compra {
  margin-top: 25px;
  width: 100%;
  background: #000;
  border: none !important;
  padding: 7px 20px;
  color: #fff;
}

.img-close-cart {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 25px;
}

.btn-cart-cantidad {
  position: absolute;
  top: 20px;
  right: 20px;
}

.btn-cant {
  margin: 5px;
  border: none !important;
  background: #000;
  color: #fff;
}

.span-total {
  position: absolute;
  top: 65px;
  right: 20px;
  font-weight: bold !important;
  opacity: 1 !important;
  color: #000 !important;
}

.total-general {
  margin-top: 35px;
  width: 100%;
  text-align: center;
}

.total-carrito-text {
  display:inline-block ;
  text-align: center;
  font-size: 20px;
  background: #000;
  color: #fff;
  padding: 10px 30px;
  border-radius: 15px;
}

.span-carrito-form {
  margin-top: 40px !important;
  margin-bottom: 20px;
}

.popup-compra-carrito {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('../images/4.jpg') center center;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000002; /* Asegúrate de que esté sobre otros elementos */
}

.popup-compra-carrito::before {
  content: ''; /* Necesario para que el pseudo-elemento se muestre */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Ejemplo de fondo semitransparente */
  z-index: -1; /* Asegúrate de que esté detrás del contenido principal */
}

.card-insiede-compra-carrito {
  background: #fff;
  padding: 60px 30px;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.12);
  width: 80%; /* Ajusta el ancho según lo necesites */
  max-width: 600px; /* Ajusta el ancho máximo según lo necesites */
  text-align: center;
}

.btn-confirmacion {
  background: #000;
  color: #fff;
  padding: 9px 20px;
  border: none !important;
  margin: 10px;
}

.text-id-confirm {
  font-size: 20px;
  font-weight: bold;
}

.title-confirm {
  opacity: 0.8;
}


.text-title-form-user-data{
  font-size: 25px;
  text-align: center;
  margin: 30px auto;
}